import consumer_web_policyConfig_cityTemplateDetail from '@/lib/data-service/haolv-default/consumer_web_policyConfig_cityTemplateDetail'
export default {
    data() {
        return {
            loading: false,
            templateId: '',
            form: {
                templateId: '',
                templateName: '',
                sortList: [],
            },
            otherForm: {
                templateName: '其他城市',
            },
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
        this.reactForm();
        this.templateId = this.$route.query.templateId || '';
        if (this.templateId) {
            this.getDetail();
        }
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        reactForm() {
            this.form = {
                templateId: '',
                templateName: '',
                sortList: [],
            }
        },
        getDetail() {
            this.loading = true;
            consumer_web_policyConfig_cityTemplateDetail({templateId: this.templateId}).then((res)=>{
                let data = res.datas;
                let delIndex = '';
                data.sortDetailList.forEach((value, index)=>{
                    if (value.sortName === '其他城市') {
                        delIndex = index
                    }
                    let cityIds = [];
                    value.sortCityIds.forEach(value1=>{
                        cityIds.push(value1.cityId)
                    });
                    value.cityIds = cityIds;

                })
                data.sortList = data.sortDetailList;
                data.sortList.splice(delIndex, 1);
                delete data['sortDetailList'];
                this.form = data

            }).finally(()=>{
                this.loading = false;
            })
        },
        back() {
            this.$router.go(-1);
        },
    }
}
