// consumer_web_policyConfig_cityTemplateDetail
const __request = require(`./__request/__request_contentType_json`);
const consumer_web_policyConfig_cityTemplateDetail = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/consumer/web/policyConfig/cityTemplateDetail',
    data: pParameter
  };
  return new Promise(function(resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_web_policyConfig_cityTemplateDetail;
